<mat-card>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <form (submit)="onSaveReport(reportForm)" #reportForm="ngForm">
        <mat-card-title>Create Report</mat-card-title>
        <mat-form-field>
            <textarea matInput rows="1" name="date" #appID="ngModel" [ngModel]="data?.date" placeholder="Date"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="1" name="appID" #appID="ngModel" [ngModel]="data?.appID" required placeholder="AppID"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="1" name="version" #version="ngModel" [ngModel]="data?.version" required placeholder="VersionID"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="1" name="userID" #userID="ngModel" [ngModel]="data?.userID" required placeholder="UserID"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="1" name="messageType" #messageType="ngModel" [ngModel]="data?.messageType" required placeholder="MessageType"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="1" name="message" #message="ngModel" [ngModel]="data?.message" required placeholder="Message"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <mat-form-field>
            <textarea matInput rows="1" name="meta" #meta="ngModel" [ngModel]="data?.meta" required placeholder="Meta"></textarea>
            <mat-error *ngIf="appID.invalid">Invalid</mat-error>
        </mat-form-field>
        <hr>
        <button mat-raised-button color="primary">Save Report</button>
    </form>
</mat-card>