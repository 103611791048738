import { Subject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { Report } from "./report.model";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class ReportService {

    private path: string = "http://appreport.redironlabs.com:3000/api/";
    //private path: string = "http://localhost:3000/api/";
    private reports: Report[] = [];
    private reportsUpdated = new Subject<{ reports: Report[], reportCount: number }>();

    constructor(private httpClient: HttpClient, private router: Router) {
    }

    getReports(postsPerPage: number, currentPage: number, editor: boolean, fromDate: Date, toDate: Date) {
        const isoFromDate = fromDate.toISOString();
        const isoToDate = toDate.toISOString();
        const queryParams = `?pagesize=${postsPerPage}&page=${currentPage}&editor=${editor}&fromDate=${isoFromDate}&toDate=${isoToDate}`;
        this.httpClient
            .get<{ message: string, reports: any, maxPosts: number }>(this.path + "reports" + queryParams)
            .pipe(
                map(reportsData => { // we need to rename ._id to .id
                    return {
                        reports: reportsData.reports.map(report => { // return reports
                            // dark magic to replace _id
                            const newReport = { ...report };
                            newReport.id = report._id;
                            delete newReport._id;
                            return newReport;
                        }),
                        maxPosts: reportsData.maxPosts // also return maxPosts
                    };
                }),
            )
            .subscribe((reportsMap) => {
                this.reports = reportsMap.reports;
                this.reportsUpdated.next({
                    reports: [...this.reports],
                    reportCount: reportsMap.maxPosts
                });
            });

        //return [...this.reports];
    }

    getPostUpdateListener() {
        return this.reportsUpdated.asObservable();
    }

    getReport(id: string) {
        return this.httpClient.get<{ message: string, reports: any }>(this.path + "reports/" + id);
        //{ ...this.reports.find(x => x.id === id) };
    }

    addReports(report: Report) {
        this.httpClient.post<{ message: string, id: string, date: Date }>(this.path + "reports", report)
            .subscribe((responseData) => {
                console.log(responseData.message)
                this.router.navigate(["/"]);  // also refreshes data
            });
    }

    updateReport(report: Report) {
        this.httpClient.put(this.path + "reports/" + report.id, report)
            .subscribe((responseData) => {
                this.router.navigate(["/"]); // also refreshes data
            });
    }

    deleteReports(id: string) {
        return this.httpClient.delete(this.path + "reports/" + id);
    }
}