<mat-toolbar color="primary">
    <span>
        <a routerLink="/">RED IRON | App Config</a>
    </span>
    <span class="spacer"></span>
    <ul>
        <li *ngIf="userIsAuthenticated">
            <a mat-button routerLink="/create" routerLinkActive="mat-accent">New Report</a>
        </li>
        <li *ngIf="!userIsAuthenticated">
            <a mat-button routerLink="/login" routerLinkActive="mat-accent">Login</a>
        </li>
        <li *ngIf="userIsAuthenticated">
            <a mat-button routerLink="/signup" routerLinkActive="mat-accent">New User</a>
        </li>
        <li *ngIf="userIsAuthenticated">
            <button mat-button (click)="onLogout()">Logout</button>
        </li>
    </ul>
</mat-toolbar>