<mat-card>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <form (submit)="onSignup(signupForm)" #signupForm="ngForm" *ngIf="!isLoading">
        <mat-card-title>Sign-up</mat-card-title>

        <mat-form-field>
            <input matInput type="email" name="email" ngModel #email="ngModel"
                placeholder="email@domain.com" required email />
            <mat-error *ngIf="email.invalid">Invalid</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput type="password" name="password" ngModel #password="ngModel"
                placeholder="password" required maxlength="18" minlength="8" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&()-/$=?]).*$"/>
            <mat-error *ngIf="password.invalid">Invalid, 8-18 char, 1 upper, 1 lower, 1 number, 1 special char</mat-error>
        </mat-form-field>

        <hr>

        <button mat-raised-button *ngIf="!isLoading" color="primary">Sign-up</button>    

    </form>
</mat-card>