<mat-card>
  <!--
  <mat-accordion multi="true" *ngIf="reports.length > 0">
    <mat-expansion-panel *ngFor="let report of reports">
      <mat-expansion-panel-header>
        {{ report.messageType }}
      </mat-expansion-panel-header>
      <p>{{ report.message }}</p>
    </mat-expansion-panel>
  </mat-accordion>
  -->
  <mat-spinner *ngIf="isLoading && userIsAuthenticated"></mat-spinner>

  <p class="mat-body-1" *ngIf="!userIsAuthenticated">Login required</p>
  <a mat-button *ngIf="!userIsAuthenticated" routerLink="/login" routerLinkActive="mat-accent">Login</a>

  <p class="mat-body-1" *ngIf="reports.length <= 0 && !isLoading && userIsAuthenticated">No reports found</p>


  <span *ngIf="userIsAuthenticated">

    <button *ngIf="editor" mat-button color="primary" (click)="onChangeEditor()">Hide Dev Builds</button>
    <button *ngIf="!editor" mat-button color="primary" (click)="onChangeEditor()">Show Dev Builds</button>

    <mat-form-field appearance="fill">
      
      <mat-label>Enter a date range</mat-label>
      
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="Start date" [ngModel]="startDate" name="startDate" #startDate="ngModel">
        <input matEndDate placeholder="End date"[ngModel]="endDate" name="endDate" #endDate="ngModel">
      </mat-date-range-input>
      
      <mat-hint>MM/DD/YYYY – MM/DD/YYYY</mat-hint>

      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>

    </mat-form-field>

    <button mat-button color="primary" (click)="onRefresh()">Refresh</button>

  </span>

  <table mat-table [dataSource]="reports" *ngIf="reports.length > 0 && userIsAuthenticated" matSort
    (matSortChange)="sortData($event)">

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="date"> Date </th>
      <td mat-cell *matCellDef="let element"> {{element.date | date: 'yyyy/MM/dd h:mm:ss a'}} </td>
    </ng-container>

    <ng-container matColumnDef="ipAddress">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> IP </th>
      <td mat-cell *matCellDef="let element"> {{element.ipAddress}} </td>
    </ng-container>

    <ng-container matColumnDef="appID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> App ID </th>
      <td mat-cell *matCellDef="let element"> {{element.appID}} </td>
    </ng-container>

    <ng-container matColumnDef="buildID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Build ID </th>
      <td mat-cell *matCellDef="let element"> {{element.buildID}} </td>
    </ng-container>

    <ng-container matColumnDef="version">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Version </th>
      <td mat-cell *matCellDef="let element"> {{element.version}} </td>
    </ng-container>

    <ng-container matColumnDef="userID">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="userID"> User ID </th>
      <td mat-cell *matCellDef="let element"> {{ (element.userID.length>10)? (element.userID |
        slice:0:10)+'...':(element.userID) }} </td>
    </ng-container>

    <ng-container matColumnDef="messageType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Type </th>
      <td mat-cell *matCellDef="let element"> {{element.messageType}} </td>
    </ng-container>

    <ng-container matColumnDef="message">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Message </th>
      <td mat-cell *matCellDef="let element"> {{ (element.message.length>20)? (element.message |
        slice:0:20)+'...':(element.message) }} </td>
    </ng-container>

    <ng-container matColumnDef="meta">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Meta </th>
      <td mat-cell *matCellDef="let element"> {{ (element.meta.length>30)? (element.meta |
        slice:0:30)+'...':(element.meta) }} </td>
    </ng-container>

    <ng-container matColumnDef="result" hidden="true">
      <th mat-header-cell *matHeaderCellDef mat-sort-header hidden="true"> Result </th>
      <td mat-cell *matCellDef="let element" hidden="true"> {{element.result}} </td>
    </ng-container>

    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <!-- <button mat-button color="primary" (click)="onEdit(element.messageID)">EDIT</button>  {{element.id}} -->
        <a *ngIf="userIsAuthenticated" mat-button color="warn" [routerLink]="['/edit', element.id]">EDIT</a>
        <button *ngIf="userIsAuthenticated" mat-button color="primary" (click)="onDelete(element.id)">DELETE</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
  <mat-paginator [length]="totalResults" *ngIf="reports.length > 0" [pageSize]="totalPerPage"
    [pageSizeOptions]="pageSizeOptions" (page)="onChangedPage($event)"></mat-paginator>
</mat-card>
<!-- 
<mat-grid-list cols="5" *ngFor="let report of reports">
  <mat-grid-tile>{{ report.date }} </mat-grid-tile>
  <mat-grid-tile>{{ report.appID + " " + report.version }} </mat-grid-tile>
  <mat-grid-tile>{{ report.userID }}</mat-grid-tile>
  <mat-grid-tile>{{ report.messageType }}</mat-grid-tile>
  <mat-grid-tile>{{ report.message }}</mat-grid-tile>
</mat-grid-list>
-->