import { Component, EventEmitter, Output, ComponentFactoryResolver, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, ParamMap } from "@angular/router";

import { Report } from "../report.model";
import { ReportService } from "../report.service";

@Component({
    selector: 'app-reports-create',
    templateUrl: './reports-create.component.html',
    styleUrls: ['./reports-create.component.css']
})

export class ReportsCreateComponent implements OnInit {

    constructor(public reportService: ReportService, public route: ActivatedRoute) { }

    private mode = "create";
    private id: string;
    data: Report;
    isLoading: boolean;

    ngOnInit(): void {
        this.route.paramMap.subscribe((paramMap: ParamMap) => {
            if (paramMap.has('id')) {
                this.mode = "edit";
                this.id = paramMap.get("id");                
                this.isLoading = true;
                this.reportService.getReport(this.id).subscribe(report => {
                    this.isLoading = false;
                    this.data = {
                        qid: "",
                        ipAddress: report.reports.ipAddress,
                        id: report.reports.id,
                        appID: report.reports.appID,
                        date: report.reports.date,
                        message: report.reports.message,
                        messageType: report.reports.messageType,
                        meta: report.reports.meta,
                        userID: report.reports.userID,
                        version: report.reports.version,
                        result: report.reports.value,
                        hidden: report.reports.hidden,
                        vendorID: report.reports.vendorID
                    };
                });
            } else {
                this.mode = "create";
                this.id = null;
            }
        });
    }

    onSaveReport(form: NgForm) {

        if (form.invalid) {
            const report: Report = {
                //id: this.index,
                qid: "m0nk3y!",
                ipAddress: "0.0.0.0",
                appID: "NAFG-PROTO",
                date: new Date(),
                message: "Test message, this is a test message",
                messageType: "WARN",
                meta: "Some meta data",
                userID: "PINO:114.14.42.144",
                version: "1.0.0",
                result: 0,
                hidden: false,
                vendorID: "REDIRON"
            }

            this.reportService.addReports(report);
            return;
        }

        this.isLoading = true;
        var thisDate = new Date(form.value.date);
        if (!thisDate)
            thisDate = new Date();

        const report: Report = {
            //id: this.index,
            qid: "m0nk3y!",
            ipAddress: "0.0.0.0",
            appID: form.value.appID,
            date: thisDate,
            message: form.value.message,
            messageType: form.value.messageType,
            meta: form.value.meta,
            userID: form.value.userID,
            version: form.value.version,
            result: form.value.value,
            hidden: false,
            vendorID: form.value.vendorID
        }

        if (this.mode == "create") {
            this.reportService.addReports(report);
        }
        else {
            report.id = this.id;
            this.reportService.updateReport(report);
        }

        form.resetForm();
    }
}
