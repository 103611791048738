import { Component, Input, ComponentFactoryResolver, ViewChild, OnInit } from "@angular/core";
import { MatTable } from "@angular/material/table";
import { Report } from "../report.model";
import { ReportService } from "../report.service";
import { Subscription } from "rxjs";
import { Sort } from "@angular/material/sort";
import { PageEvent } from "@angular/material/paginator";
import { ReportsCreateComponent } from "../reports-create/reports-create.component";
import { AuthService } from "src/app/auth/auth.service";

@Component({
    selector: 'app-reports-list',
    templateUrl: './reports-list.component.html',
    styleUrls: ['./reports-list.component.css']
})

export class ReportsListComponent implements OnInit {
    // data = [
    //     { title: "title1", content: "content1" },
    //     { title: "title2", content: "content2" },
    //     { title: "title3", content: "content3" }
    // ]
    userIsAuthenticated = false;
    reports: Report[] = [];
    isLoading: boolean;

    private reportSub: Subscription = new Subscription;
    private authListenerSubs: Subscription;

    startDate: Date;
    endDate: Date = new Date();

    editor = false;
    totalResults = 0;
    totalPerPage = 10;
    currentPage = 1;
    pageSizeOptions = [1, 2, 10, 25, 50, 100, 1000];

    constructor(public reportService: ReportService, private authService: AuthService) { 
        const currentDate = new Date();
        this.startDate = new Date(currentDate.setFullYear(currentDate.getFullYear() - 10));
    }

    ngOnInit(): void {
        this.userIsAuthenticated = this.authService.getAuthStatus();
        this.authListenerSubs = this.authService
            .getAuthStatusListener()
            .subscribe(isAuthenticated => {
                this.userIsAuthenticated = isAuthenticated;
            });

        if (this.userIsAuthenticated) {
            this.reportService.getReports(this.totalPerPage, this.currentPage, this.editor, this.startDate, this.endDate);
            this.isLoading = true;
        }
        this.reportSub = this.reportService.getPostUpdateListener()
            .subscribe((reportData: { reports: Report[], reportCount: number }) => { // needs cleanup, see subscription
                this.isLoading = false;
                this.totalResults = reportData.reportCount;
                this.reports = reportData.reports;
            });
    }
    ngOnDestroy() {
        this.reportSub.unsubscribe();
        this.authListenerSubs.unsubscribe();
    }

    onEdit(id: string) {
    }

    onRefresh(){
        console.log("date range: " + this.startDate, this.endDate);
        this.reportService.getReports(this.totalPerPage, this.currentPage, this.editor, this.startDate, this.endDate);
    }

    onChangeEditor(){
        console.log("date range: " + this.startDate, this.endDate);
        this.editor = !this.editor;
        this.reportService.getReports(this.totalPerPage, this.currentPage, this.editor, this.startDate, this.endDate);
    }

    onDelete(id: string) {
        this.isLoading = true;
        this.reportService.deleteReports(id).subscribe(() => {
            this.isLoading = false;
            this.reportService.getReports(this.totalPerPage, this.currentPage, this.editor, this.startDate, this.endDate);
        });
    }

    displayedColumns: string[] = ['date', 'ipAddress', 'appID', 'version', 'userID', 'messageType', 'message', 'meta', 'result', 'action'];

    onChangedPage(pageData: PageEvent) {
        this.currentPage = pageData.pageIndex + 1;
        this.totalPerPage = pageData.pageSize;
        this.reportService.getReports(this.totalPerPage, this.currentPage, this.editor, this.startDate, this.endDate);
    }

    sortData(sort: Sort) {

        const data = this.reports.slice();

        if (!sort.active || sort.direction === '') {
            this.reports = data;
            return;
        }

        this.reports = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'date':
                    return compare(a.date.toString(), b.date.toString(), isAsc);
                case 'userID':
                    return compare(a.userID, b.userID, isAsc);
                case 'appID':
                    return compare(a.appID, b.appID, isAsc);
                case 'messageType':
                    return compare(a.messageType, b.messageType, isAsc);
                case 'message':
                    return compare(a.message, b.message, isAsc);
                case 'version':
                    return compare(a.version, b.version, isAsc);
                case 'meta':
                    return compare(a.meta, b.meta, isAsc);
                default:
                    return 0;
            }
        });
    }
}

function compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}
