import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Subject } from "rxjs";
import { AuthData } from "./auth-data.model";

@Injectable({ providedIn: 'root' })
export class AuthService {

    private isAuthenticated = false;
    private token: string;
    private tokenTimer: any;
    private authStatusListener = new Subject<boolean>();

    constructor(private httpClient: HttpClient, private router: Router) {
    }

    private path: string = "http://appreport.redironlabs.com:3000/api/";
    //private path: string = "http://localhost:3000/api/";

    getToken() {
        return this.token;
    }

    getAuthStatus() {
        return this.isAuthenticated;
    }

    getAuthStatusListener() {
        return this.authStatusListener.asObservable();
    }

    createUser(email: string, password: string) {
        const authData: AuthData = { email: email, password: password };

        this.httpClient.post<{ token: string }>(this.path + "user/signup/", authData)
            .subscribe((response) => {
                console.log(response)
            });
    }

    login(email: string, password: string) {
        const authData: AuthData = { email: email, password: password };

        this.httpClient.post<{ token: string, expiresIn: number }>(this.path + "user/login/", authData)
            .subscribe((response) => {
                // console.log(responseData)
                const token = response.token;
                if (token) {
                    const expiresInDuration = response.expiresIn;

                    this.setAuthTimer(expiresInDuration);

                    this.token = token;
                    this.isAuthenticated = true;
                    this.authStatusListener.next(true);

                    const now = new Date();
                    const expirationDate = new Date(now.getTime() + (expiresInDuration * 1000));

                    this.saveAuthData(token, expirationDate);
                    this.router.navigate(["/"]);
                }
            });
    }

    private setAuthTimer(expiresInDuration: number) {
        this.tokenTimer = setTimeout(() => {
            this.logout();
        }, expiresInDuration * 1000);
    }

    autoAuthUser() {
        const authInformation = this.getAuthData();
        if (!authInformation){
            return;
        }

        const now = new Date();
        const expiresIn = authInformation.expirationDate.getTime() - now.getTime();

        // console.log(authInformation, expiresIn);
        if (expiresIn > 0) {
            this.token = authInformation.token;
            this.isAuthenticated = true;
            this.authStatusListener.next(true);
            this.setAuthTimer(expiresIn / 1000)
        }
    }

    logout() {
        this.token = null;
        this.isAuthenticated = false;
        this.authStatusListener.next(false);
        this.router.navigate(["/login"]);
        clearTimeout(this.tokenTimer);
    }

    private saveAuthData(token: string, expirationDate: Date) {
        localStorage.setItem('token', token);
        localStorage.setItem('expirationDate', expirationDate.toISOString());
    }

    private clearAuthData() {
        localStorage.removeItem('token');
        localStorage.removeItem('expirationDate');
    }

    private getAuthData() {
        
        const token = localStorage.getItem("token");
        const expirationDate = localStorage.getItem("expirationDate");
        
        if (!token && !expirationDate) {
            return {};
        }

        return {
            token: token,
            expirationDate: new Date(expirationDate)
        }
    }
}