<mat-card>
    <mat-spinner *ngIf="isLoading"></mat-spinner>
    <form (submit)="onLogin(loginForm)" #loginForm="ngForm" *ngIf="!isLoading">
        <mat-card-title>Login</mat-card-title>

        <mat-form-field>
            <input matInput type="email" name="email" ngModel #emailInput="ngModel"
                placeholder="email@domain.com" required email />
            <mat-error *ngIf="emailInput.invalid">Invalid</mat-error>
        </mat-form-field>

        <mat-form-field>
            <input matInput type="password" name="password" ngModel #passwordInput="ngModel"
                placeholder="password" required maxlength="18" minlength="8" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#&()-/$=?]).*$"/>
            <mat-error *ngIf="passwordInput.invalid">Invalid, 8-18 char, 1 upper, 1 lower, 1 number, 1 special char</mat-error>
        </mat-form-field>

        <hr>

        <button *ngIf="!isLoading" mat-raised-button color="primary">Login</button>  
          
    </form>
</mat-card>